@import 'assets/_constants';
@import 'assets/_mixins';

.back-logs-panel {
  @include basic-shadow;
  height: calc(100vh - #{$topbar-height} - 20rem);
  display: flex;
  flex-direction: column;
  padding: 2rem;

  .back-logs-panel-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    align-items: center;
    .back-logs-panel-form-hostname {
      margin-left: 0.5rem;;
    }
  }

  .back-logs-panel-logs-wrapper {
    overflow-y: scroll;
    flex-grow: 1;
    border-width: 1px 0 1px 1px;
    border-color: #DDD;
    border-style: solid;
    
    .back-logs-panel-logs-item {
      padding: 0 0.5rem 0 0;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-bottom: 1px solid #CCC;

      // &.odd{ }
      &.pair{
          background-color: #EEE;
      }
    }
  }

  .loading-panel {
    flex-grow: 1;
    @include flex-center;
  }

}