@import 'assets/_constants';
@import 'assets/_mixins';

.f-file-manager {
  width: 100%;
  padding: 0.5rem;
  
  .f-file-manager-breadcrumb {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 0.2rem;
    border-bottom: 0.1rem solid $gray-700;
    margin-bottom: 0.5rem;

    .f-file-manager-breadcrumb-separator {
      margin: 0 0.5rem 0 0.5rem;
    }
  }

  .f-file-manager-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 0.2rem;
    border-bottom: 0.1rem solid $gray-700;
    margin-bottom: 0.5rem;

    .f-file-manager-actions-button-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 0.5rem;
      }
      
    &:not(.show-labels) {
      .funus-button {
        .button-prefix {
          margin-right: 0;;
        } 
        .button-text {
          display: none;;
        }
      } 
    }
  }

  .f-file-manager-container {
    display: flex;
    justify-content: flex-start;
    height: calc(100vh - 30rem);
    border: 0.1rem solid $gray-300;
    padding: 0 0.3rem;
    overflow-y: scroll;

    .f-file-manager-container-item {
      &.selected {
        background-color: #CCC;
      }
    }
  }

  &.icons-view {
    .f-file-manager-container {
      flex-direction: column;
      flex-wrap: wrap;
      overflow-x: auto;

      .f-file-manager-container-item {
        width: 25%;

        .f-file-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 3rem;
          .icon {
            margin-right: 0.3rem;
          }
          .mime-type {
            display: none;;
          }
        }
      }
    }
  }

  &.icons-big-view {
    .f-file-manager-container {
      flex-direction: column;
      flex-wrap: wrap;
      overflow-x: scroll;

      .f-file-manager-container-item {
        width: 33.3%;

        .f-file-item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          height: 3rem;
        }
      }
    }
  }

  &.detail-view {
    .f-file-manager-container {
      flex-direction: column;
      overflow-y: scroll;

      .f-file-manager-container-item {
        width: 100%;

        .f-file-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .icon {
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
}

.funus-step-form>form .form-body>* .step-info .step-fields fieldset>*:not(legend) {
  &.f-file-manager {
    width: 100%;
  }
}